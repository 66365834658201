








import Vue from 'vue'
import { SignUpForm } from '@/components/sign-up'
import { Component } from 'vue-property-decorator'

@Component({
    components: {
        SignUpForm: SignUpForm
    }
})
export default class SignUp extends Vue {}
