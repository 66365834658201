


























import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import Checkbox from '@/infrastructure/ui/Checkbox.vue'
import Gdpr from '@/components/legal-matters/gdpr.vue'

@Component({
    components: { Gdpr, Checkbox }
})
export default class AcceptLegalMatters extends Vue {
    @VModel({ required: true }) acceptedGDPR!: boolean
    @Prop({ required: true }) errors!: string[]

    showGDPR = false

    get error(): boolean {
        return !!this.errors && this.errors.length > 0
    }

    get infoStyles(): string {
        return `flex-grow-1 flex-shrink-0 align-self-center ${this.error && 'error--text'}`
    }
}
