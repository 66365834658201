














import { Component, Prop, VModel, Vue } from 'vue-property-decorator'

@Component<Checkbox>({})
export default class Checkbox extends Vue {
    @Prop({ type: String }) label!: string
    @Prop({ default: () => [] }) errorMessages!: string[]
    @Prop() error!: boolean
    @VModel({ type: Boolean }) checkbox!: boolean
}
