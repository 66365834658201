
































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { TextInput } from '@/components/form'
import Button from '@/infrastructure/ui/button.vue'
import { email, maxLength, minLength, not, required, sameAs } from 'vuelidate/lib/validators'
import { getModule } from 'vuex-module-decorators'
import { UserStore } from '@/infrastructure/store/user'
import Checkbox from '@/infrastructure/ui/Checkbox.vue'
import { consume, PATH, provide } from '@/utils/provide-consum-decorator'
import store from '@/infrastructure/store'
import { IUserStore } from '@/infrastructure/store/user/types'
import AcceptLegalMatters from '@/components/sign-up/accept-legal-matters.vue'
import { ERROR_CODE } from '@/application/Errors'

@Component<SignUpForm>({
    components: {
        AcceptLegalMatters,
        Button,
        Checkbox,
        TextInput
    },
    validations() {
        return {
            name: {
                required,
                minLength: minLength(3),
                maxLength: maxLength(30)
            },
            email: {
                required,
                email,
                unique: not(sameAs(this.existingEmail))
            },
            password: {
                required,
                minLength: minLength(7),
                maxLength: maxLength(30)
            },
            repeat: {
                required,
                sameAsPassword: sameAs('password')
            },
            acceptedGDPR: {
                checked: (value) => value === true
            }
        }
    }
})
@provide({
    [PATH.USER_API]: () => {
        return getModule(UserStore, store)
    }
})
export default class SignUpForm extends Vue {
    @consume(PATH.USER_API) user!: IUserStore

    @Prop({ default: 'asd.vue' }) title!: string
    name = ''
    email = ''
    password = ''
    repeat = ''
    acceptedGDPR = false

    loading = false
    existingName = ''
    existingEmail = ''

    async submit(): Promise<void> {
        try {
            this.loading = true
            const result = await this.user.registerUser({
                name: this.name,
                email: this.email,
                password: this.password,
                acceptedGDPR: this.acceptedGDPR
            })
            await this.$router.push({ name: 'Home' })
        } catch (e) {
            if (e.message === ERROR_CODE.EMAIL_ALREADY_USED) {
                this.existingEmail = this.email
            }
            if (e.message === ERROR_CODE.NAME_ALREADY_USED) {
                this.existingName = this.name
            }
        } finally {
            this.$v.$reset()
            this.loading = false
        }
    }

    get disabled(): boolean {
        return !this.acceptedGDPR || this.$v.$anyError || this.loading || !this.$v.$anyDirty
    }

    get nameErrors(): string[] {
        const errors: string[] = []
        const { name } = this.$v
        if (!name.$dirty && this.existingName.length < 1) {
            return errors
        }

        !name.required && errors.push('required')
        !name.minLength && errors.push('must be at least 3 characters long')
        !name.maxLength && errors.push('must be at most 30 characters long')
        if (this.name === this.existingName) {
            errors.push('Dieser Name wird bereits verwendet')
        }

        return errors
    }

    get emailErrors(): string[] {
        const errors: string[] = []
        const { email } = this.$v
        if (!email.$dirty && this.existingEmail.length < 1) {
            return errors
        }

        !email.required && errors.push('required')
        !email.email && errors.push('please enter valid e-mail address')
        if (this.email === this.existingEmail) {
            errors.push('Es existiert bereits ein Account mit dieser E-Mail-Adresse')
        }

        return errors
    }

    get passwordErrors(): string[] {
        const errors: string[] = []
        const { password } = this.$v
        if (!password.$dirty) {
            return errors
        }

        !password.required && errors.push('required')
        !password.minLength && errors.push('must be at least 7 characters long')
        !password.maxLength && errors.push('must be at most 30 characters long')

        return errors
    }

    get repeatPasswordErrors(): string[] {
        const errors: string[] = []
        const { repeat } = this.$v
        if (!repeat.$dirty) {
            return errors
        }

        !repeat.required && errors.push('required')
        !repeat.sameAsPassword && errors.push('does not match password')

        return errors
    }

    get acceptedGDPRErrors(): string[] {
        const errors: string[] = []
        const { acceptedGDPR } = this.$v
        if (!acceptedGDPR.$dirty) {
            return errors
        }

        !acceptedGDPR.checked && errors.push('Um sich anzumelden muss den verlinkten Inhalten zugestimmt werden')

        return errors
    }
}
